<template>
  <div>
    <!--dashboard main body start-->
    <div class="dashboard-body dashboard-invoice">
      <div class="dashboard-body-content d-flex align-items-start flex-column">
        <div class="page-global-header px-0">
          <div class="row justify-content-end mx-0">
            <form class="base-form">
              <div class="row d-flex align-items-center justify-content-between flex-row">
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div class="input-group input-group--3d p-0">
                    <div class="input-group-text default prepend">
                      <img src="@/assets/images/system/add-user-blue.png" />
                    </div>
                    <input
                      type="text"
                      name="itemTitle"
                      class="form-control"
                      placeholder="Type item name or title*"
                      v-model="clientName"
                      @click="getClientData"
                      data-bs-target="#getClient"
                      data-bs-toggle="modal"
                      autocomplete="off"
                    />
                    <div class="input-group-text transparent p-0 append flush-before">
                      <button type="button" name="btn" class="btn btn-white _sm">
                        <i
                          class="fa fa-search"
                          data-bs-target="#getClient"
                          data-bs-toggle="modal"
                          @click="getClientData"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div class="input-group p-0">
                    <span class="input-group-text prepend flush-after"> Date </span>
                    <input
                      type="date"
                      name="Invoice_date"
                      class="form-control datepicker"
                      v-model="invoice_date"
                      onfocus="this.showPicker()"
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div class="input-group p-0">
                    <span class="input-group-text prepend flush-after"> Terms </span>
                    <select
                      name="paymenetMethod"
                      v-model="terms"
                      class="form-select"
                      required
                    >
                      <option v-for="day in 32" :key="day" :value="day - 1">
                        {{ day - 1 }} Days
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-auto">
                  <button class="btn btn-white" type="button">
                    Invoice No: {{ invoiceNumber }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="w-100 my-3 px-2">
          <div class="white-shadebox">
            <ul class="nav nav-fill nav--iconicshade">
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  data-bs-target="#addItemToList"
                  data-bs-toggle="modal"
                >
                  <span class="nav-icon shade">
                    <img src="@/assets/images/system/plus-orange.png"
                  /></span>
                  <span class="nav-text">Add Item</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  data-bs-target="#addPayment"
                  data-bs-toggle="modal"
                >
                  <span class="nav-icon shade"
                    ><img src="@/assets/images/system/dollar.png"
                  /></span>
                  <span class="nav-text">Payment</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  data-bs-target="#addDiscount"
                  data-bs-toggle="modal"
                >
                  <span class="nav-icon shade">
                    <img src="@/assets/images/system/percent-orange.png" />
                  </span>
                  <span class="nav-text">Discount</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  data-bs-target="#addAttachment"
                  data-bs-toggle="modal"
                >
                  <span class="nav-icon shade"
                    ><img src="@/assets/images/system/camera-blues.png" />
                    <span class="has-badge" v-if="image_count > 0">{{
                      image_count
                    }}</span>
                  </span>
                  <span class="nav-text">Attachment</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  data-bs-target="#addNote"
                  data-bs-toggle="modal"
                >
                  <span class="nav-icon shade"
                    ><img src="@/assets/images/system/edit-orange.png" />
                    <span class="has-badge" v-if="note != ''"
                      ><i class="fa fa-check"></i
                    ></span>
                  </span>
                  <span class="nav-text">Note</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="page-body">
          <div class="row has-vr-devider position-relative mt-5 mx-0">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <div class="contact-card-group">
                <!--repeatable block-->
                <div
                  class="card card-flex-row _v2"
                  v-for="(item, itemIndex) in itemPaginatedList"
                  :key="itemIndex"
                >
                  <div class="card-header">
                    <span class="badge-count">{{ itemIndex + 1 }}.</span>
                  </div>
                  <div class="card-body">
                    <ul class="cmn-vrList">
                      <li class="list-title">{{ item.category_type }}</li>
                      <li class="sub-title">{{ item.name }}</li>
                      <li class="mt-2">{{ item.description }}</li>
                      <li class="price-tag">
                        {{ parseInt(item.quantity) }} x
                        {{ parseFloat(item.unit_price).toFixed(2) }}
                      </li>
                    </ul>
                  </div>
                  <div
                    class="card-footer justify-content-between flex-column align-items-end"
                  >
                    <ul class="hr-navList">
                      <li class="nav-item ellipsis-dropdown dropdown">
                        <a
                          href="#"
                          class="nav-link dropdown-toggle no-caret"
                          id="edit-act-1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fas fa-ellipsis-v"></i
                        ></a>
                        <ul class="dropdown-menu" aria-labelledby="edit-act-1">
                          <li>
                            <a
                              class="dropdown-item delete"
                              href="#"
                              @click="removeItem(item.item_no)"
                              ><i class="fa fa-times"></i>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="ftr-price-tag text-secondary">
                      <span v-if="item.type === 'parts'">
                        <i class="fas fa-cog"></i>
                      </span>
                      <span v-else>
                        <i class="fas fa-running"></i>
                      </span>
                    </div>
                    <div class="ftr-price-tag">
                      &pound;{{
                        parseInt(item.quantity) * parseFloat(item.unit_price).toFixed(2)
                      }}
                    </div>
                  </div>
                </div>

                <!-- Pagination -->
                <div class="mt-4">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="selectedItemsList.length"
                    :page-size="itemPageSize"
                    :current-page="itemCurrentPage"
                    @current-change="itemHandlePageChange"
                  />
                </div>
                <!--ended repeatable block-->
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5 ms-auto">
              <form class="base-form ps-xl-5">
                <div class="row m-0 mb-3 pe-xl-5">
                  <div class="input-group _group-xl flush-shadow p-0">
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Amount
                    </span>
                    <input
                      type="text"
                      name="amount"
                      class="form-control"
                      v-model="totalPrice"
                      disabled
                    />
                  </div>
                </div>

                <!-- <div class="row m-0 mb-3 pe-xl-5" v-if="discount > 0"> -->
                <div class="row m-0 mb-3 pe-xl-5">
                  <div class="input-group _group-xl flush-shadow p-0">
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Discount ({{ isPercentChecked ? "%" : "$" }})
                    </span>
                    <input
                      type="text"
                      name="amount"
                      class="form-control"
                      v-model="discountTypeValue"
                      disabled
                    />
                  </div>
                </div>

                <div class="row m-0 mb-3 pe-xl-5">
                  <div class="input-group _group-xl flush-shadow p-0">
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Sub-Total
                    </span>
                    <input
                      type="text"
                      name="amount"
                      class="form-control"
                      v-model="subTotalPrice"
                      disabled
                    />
                  </div>
                </div>

                <div class="row m-0 mb-3 pe-xl-5" v-if="tax_1_status_enable">
                  <!-- <div class="row m-0 mb-3 pe-xl-5"> -->
                  <div class="input-group _group-xl flush-shadow p-0">
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Tax (Vat) ({{ tax_1_rate }}%)
                    </span>
                    <input
                      type="text"
                      name="amount"
                      class="form-control"
                      v-model="vatTax"
                    />
                  </div>
                </div>

                <div class="row m-0 mb-3 pe-xl-5" v-if="tax_2_status_enable">
                  <!-- <div class="row m-0 mb-3 pe-xl-5"> -->
                  <div class="input-group _group-xl flush-shadow p-0">
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Tax 2 ({{ tax_2_rate }}%)
                    </span>
                    <input
                      type="text"
                      name="amount"
                      class="form-control"
                      v-model="vatTax2"
                    />
                  </div>
                </div>

                <div class="row m-0 mb-3 pe-xl-5">
                  <!-- <div class="row m-0 mb-3 pe-xl-5"> -->
                  <div class="input-group _group-xl flush-shadow p-0">
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Total
                    </span>
                    <input
                      type="text"
                      name="total_amount"
                      class="form-control"
                      v-model="GrandTotal"
                    />
                  </div>
                </div>

                <div class="row m-0 mb-3 pe-xl-5">
                  <div
                    class="input-group _group-xl input-group--3d flush-shadow group-half p-0"
                  >
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Payments
                    </span>
                    <div class="form-floating">
                      <label class="">{{ currency_symbol }}</label>
                      <input
                        type="text"
                        name="amount"
                        class="form-control"
                        :value="totalSubmittedAmount"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row m-0 mb-3 pe-xl-5">
                  <div
                    class="input-group _group-xl input-group--3d flush-shadow group-half p-0"
                  >
                    <span
                      class="input-group-text justify-content-end prepend flush-after"
                    >
                      Outstanding Due
                    </span>
                    <div class="form-floating">
                      <label class="floating-label highlight">{{
                        currency_symbol
                      }}</label>
                      <input
                        type="text"
                        name="amount"
                        class="form-control highlight"
                        v-model="outstanding_due"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row m-0 mb-3 pe-xl-5 mt-5 d-flex justify-content-end">
                  <div
                    class="col-sm-12 p-0 d-flex align-items-center justify-content-end"
                  >
                    <button class="btn btn-offWhite">Cancel</button>
                    <button
                      type="button"
                      class="btn btn-success btn-shadow ms-5"
                      @click="saveInvoice"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--dashboard main body end-->

    <!--add browse Modal for client-->
    <div
      class="modal fade mdl-messaging _info"
      id="getClient"
      aria-labelledby="getClientLabel"
      tabindex="-1"
    >
      <div class="modal-dialog" style="max-width: 55rem">
        <div class="modal-dialog-header">
          <div class="header-title">Client</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="row justify-content-center mb-4">
            <div class="col-sm-8">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchQuery"
                  placeholder="Search Clients"
                  aria-label="Search Clients"
                />
              </div>
            </div>
            <div class="col-sm-10">
              <a href="/add-client">Add Client</a>
            </div>
            <div
              class="col-sm-10 mt-2"
              v-for="(client, clientIndex) in displayedClients"
              :key="clientIndex"
              @click="selectClient(client)"
            >
              <!-- Repeatable block -->
              <div class="card card-flex-row">
                <div class="card-header">
                  <i class="far fa-user"></i>
                </div>
                <div class="card-body">
                  <ul class="cmn-vrList">
                    <li class="list-title">
                      <span class="card-count">{{ clientIndex + 1 }}</span>
                      <label>Co:</label>{{ client.business_name }}
                    </li>
                    <li class="sub-title"><label>N:</label>{{ client.name }}</li>
                    <li class="txt-alert"><label>E:</label>{{ client.email }}</li>
                    <li><label>T:</label>{{ client.phone1 }}</li>
                  </ul>
                </div>
                <div class="card-footer"></div>
              </div>
              <!-- End repeatable block -->
            </div>

            <div class="col-sm-10 mt-2">
              <!-- Pagination -->
              <el-pagination
                background
                layout="prev, pager, next"
                :total="filteredClients.length"
                :page-size="clientPageSize"
                :current-page="clientCurrentPage"
                @current-change="clientHandlePageChange"
              />
            </div>
          </div>
          <div class="modal-footer m-2 p-0">
            <button
              type="button"
              class="btn btn-success btn-shadow ms-5"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--add browse Modal file for item-->
    <div
      class="modal fade mdl-messaging _info"
      id="addItemToList"
      aria-hidden="true"
      aria-labelledby="addItemToListLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Add/Browse Item</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <AddItem
            from-view="add_invoice"
            @show-list-of-items="showItemListPopup"
          ></AddItem>
        </div>
      </div>
    </div>

    <!--add brows Modal file for item-->
    <el-dialog v-model="dialogVisibleItemsPopup" title="Item List" width="40%">
      <div class="float-end">
        <el-button @click="dialogVisibleItemsPopup = false">Add</el-button>
      </div>
      <div class="clearfix"></div>
      <ItemListOnlyInvoice
        :popupOpenEvent="dialogVisibleItemsPopup"
      ></ItemListOnlyInvoice>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisibleItemsPopup = false">Close</el-button>
        </span>
      </template>
    </el-dialog>

    <!--add discount-->
    <div
      class="modal fade mdl-messaging _info"
      id="addDiscount"
      aria-hidden="true"
      aria-labelledby="addDiscountLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Discount</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="row d-flex align-items-center mx-4">
            <div class="col-8">
              <el-checkbox
                v-model="isPercentChecked"
                @change="handleCheckboxChange('percent')"
                label="Percent (%)"
                size="large"
              />
              <el-checkbox
                v-model="isCashChecked"
                @change="handleCheckboxChange('cash')"
                label="Cash"
                size="large"
              />
            </div>
            <div class="col-8">
              <el-input-number
                v-if="isPercentChecked"
                v-model="discount"
                :min="0"
                :max="100"
                class="w-50"
              />
              <el-input-number
                v-else
                v-model="discount"
                :min="0"
                :max="totalPriceCalculation"
                class="w-50"
              />
            </div>
          </div>
          <div class="modal-footer m-2 p-0">
            <button
              type="button"
              class="btn btn-success btn-shadow ms-5"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--add attachment-->
    <div
      class="modal fade mdl-messaging _info"
      id="addAttachment"
      aria-hidden="true"
      aria-labelledby="addAttachmentLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Attachment</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="row mt-2rem">
            <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
              <el-carousel v-if="image_list.length > 0" :interval="2000">
                <el-carousel-item v-for="(image, index) in image_list" :key="index">
                  <img class="preview" :src="image.url" :alt="`Preview ${index + 1}`" />
                </el-carousel-item>
              </el-carousel>
              <div v-else>
                <div class="preview">No images selected.</div>
              </div>

              <div class="row">
                <div class="col-sm-12 d-flex align-items-center justify-content-center">
                  <h6>Preview ({{ image_list.length }})</h6>
                </div>
              </div>
              <div class="col-sm-12 d-flex align-items-center justify-content-center">
                <div class="custome-fileupload-circle">
                  <input
                    type="file"
                    class="file-input expense-file"
                    accept="image/jpeg, image/png"
                    @change="onFileChange"
                    multiple="multiple"
                  />
                  <div class="file-content">
                    <div class="file-icon">
                      <i class="fa fa-camera"></i>
                    </div>
                    <div class="file-info-text">Add Attachment</div>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-12 d-flex align-items-center justify-content-center mt-2"
              >
              <small>Note: Size: 512kb, png or jpeg only.</small>
              </div>
            </div>
          </div>
          <div class="modal-footer m-2 p-0">
            <button type="button" class="btn btn-offWhite" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--add note-->
    <div
      class="modal fade mdl-messaging _info"
      id="addNote"
      aria-hidden="true"
      aria-labelledby="addNoteLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Note</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="mt-2rem m-4">
            <el-input
              v-model="note"
              :rows="6"
              type="textarea"
              placeholder="Please input"
            />
          </div>
          <div class="modal-footer m-2 p-0">
            <button
              type="button"
              class="btn btn-success btn-shadow ms-5"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--add payment-->
    <div
      class="modal fade mdl-messaging _info"
      id="addPayment"
      aria-hidden="true"
      aria-labelledby="addPaymentLabel"
      tabindex="-1"
    >
      <div class="modal-dialog" style="max-width: 83rem">
        <div class="modal-dialog-header">
          <div class="header-title">Payment</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>

          <div class="page-bodyw">
            <div class="row mt-3 mx-0">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
                <div class="card card-general h-100">
                  <div class="card-body">
                    <form
                      class="form-v2 h-100 d-flex flex-column justify-content-between"
                    >
                      <div class="w-100">
                        <div class="row m-0 mb-3">
                          <div class="input-group _group-xl p-0">
                            <span
                              class="input-group-text prepend bg-bostonBlue flush-after text-start"
                            >
                              Payment Method
                              <span class="required">*</span>
                            </span>
                            <select
                              name="payment_method"
                              v-model="payment_method"
                              class="form-select"
                              required
                            >
                              <option value="" selected disabled>Please Select</option>
                              <option
                                v-for="item in payment_method_list"
                                :key="item"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="row m-0 mb-3">
                          <div class="input-group _group-xl p-0">
                            <span class="input-group-text prepend flush-after text-start">
                              Payment Date
                            </span>
                            <input
                              type="date"
                              name="payment_date"
                              v-model="payment_date"
                              onfocus="this.showPicker()"
                              class="form-control datepicker"
                              data-date-format="mm/dd/yyyy"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="w-100">
                        <div class="row m-0 mb-3 d-flex justify-content-end">
                          <div class="col-sm-9">
                            <div class="row mb-3">
                              <div class="input-group group-half p-0">
                                <div
                                  class="input-group-text bg-light-orange prepend no-after"
                                >
                                  Total Amount
                                </div>
                                <div class="form-floating">
                                  <label class="floating-label">&pound;</label>
                                  <input
                                    type="text"
                                    class="form-control text-right"
                                    placeholder="1"
                                    :value="GrandTotal"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row mb-3">
                              <div class="input-group group-half p-0">
                                <div
                                  class="input-group-text bg-light-sky no-after prepend"
                                >
                                  Outstanding Due
                                </div>
                                <div class="form-floating">
                                  <label class="floating-label">&pound;</label>
                                  <input
                                    type="text"
                                    name="due"
                                    placeholder="Due Amount"
                                    class="form-control text-right"
                                    v-model="outstanding_due"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-100">
                        <div class="row m-0 mb-3 d-flex justify-content-end">
                          <div class="col-sm-9">
                            <div class="row">
                              <div class="input-group group-half p-0">
                                <div class="input-group-text bg-success no-after prepend">
                                  Pay Now <span class="required">*</span>
                                </div>
                                <div class="form-floating">
                                  <label class="floating-label">&pound;</label>
                                  <input
                                    type="text"
                                    name="pay_now_amount"
                                    placeholder="Pay now"
                                    class="form-control text-right"
                                    v-model="pay_now_amount"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row m-0 mb-3">
                          <div class="input-group _group-xl h-auto p-0">
                            <textarea
                              class="form-control textarea-lg no-resize"
                              placeholder="Notes..."
                              name="pay_now_description"
                              v-model="pay_now_description"
                            ></textarea>
                          </div>
                          <span class="form-text text-end text-stamp"
                            >Max 255 character</span
                          >
                        </div>

                        <div class="row m-0">
                          <div class="form-check outliner">
                            <el-checkbox
                              v-model="mark_fully_paid"
                              @change="fullyPaidToggle"
                              label="Mark as Fully Paid"
                              :checked="false"
                            >
                            </el-checkbox>
                          </div>
                        </div>
                        <div class="text-end">
                          <button
                            type="button"
                            class="btn btn-success btn-shadow ms-5"
                            @click="addPayment"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
                <div class="card card-general h-100">
                  <div class="card-body">
                    <form class="form-v2">
                      <div class="row m-0 mb-3">
                        <div class="contact-card-group">
                          <!--repeatable block-->
                          <div
                            class="card card-flex-row _v3"
                            v-for="(paymnt, index) in payment"
                            :key="index"
                          >
                            <div class="card-header">
                              <span class="badge-count">1</span>
                            </div>
                            <div class="card-body">
                              <div class="card-content">
                                <ul class="label-list">
                                  <li class="sub-title">PM: {{ paymnt.method }}</li>
                                  <li class="text-danger">
                                    PD: {{ paymnt.submission_date }}
                                  </li>
                                </ul>
                              </div>

                              <div class="content-option w-auto">
                                <div
                                  class="row m-0 h-100 justify-content-end flex-column"
                                >
                                  <ul class="card-action-nav h-auto flex-row flex-nowrap">
                                    <li class="nav-item">
                                      <a href="#" class="btn btn-info btn-sm"
                                        ><i class="fa fa-edit"></i> Note
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card-footer justify-content-between flex-column align-items-end"
                            >
                              <div class="footer-list-wraper justify-content-end">
                                <ul class="footer-list _big">
                                  <li>Paid</li>
                                  <li class="count">
                                    &pound;{{ paymnt.submitted_amount }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!--ended repeatable block-->
                        </div>
                      </div>

                      <div class="row m-0 mb-3 d-flex justify-content-end">
                        <div class="col-sm-9">
                          <div class="input-group group-half p-0">
                            <div class="input-group-text bg-orange no-after prepend">
                              Total Amount
                            </div>
                            <div class="form-floating">
                              <label class="floating-label">&pound;</label>
                              <input
                                type="text"
                                class="form-control text-right"
                                placeholder="1"
                                disabled="true"
                                :value="totalSubmittedAmount"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="text-end mt-3">
                    <button
                      class="btn btn-offWhite"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";

import { CURRENCY_LIST } from "../../config/url";
import AddItem from "@/components/invoice/AddItem.vue";
import ItemListOnlyInvoice from "@/components/items/ItemListOnly.vue";

export default {
  name: "AddInvoice",
  props: {
    title: String,
    // rest of the blog post props
  },
  components: {
    AddItem,
    ItemListOnlyInvoice,
  },
  data() {
    return {
      show_total_expense: 0,
      invoice_server_data: [],
      dialogVisibleItemsPopup: false,
      isPercentChecked: true,
      isCashChecked: false,
      image_count: 0,
      searchQuery: "",
      filteredClients: [],
      // needed in form
      invoice_date: "",
      terms: 28,
      invoiceNumber: 0,
      totalPrice: 0,
      subTotalPrice: 0,
      client_server_data: [],
      client: "",
      clientName: "",
      tax_1_rate: 0,
      tax_1_name: "",
      tax_2_name: "",
      tax_vat_amount: 0,
      tax_1_status_enable: false,
      tax_2_status_enable: false,
      tax_2_rate: 0,
      tax_2_amount: 0,
      discountTypeValue: 0,
      discountType: "",
      discount: 0,
      image_list: [],
      is_image: "N",
      note: "",
      payment_method_list: "",
      payment_method: "",
      payment_date: "",
      outstanding_due: "",
      pay_now_amount: "",
      pay_now_description: "",
      payment: [],
      currency_symbol: "$",
      currency_id: this.$store.state.auth.user.currency_id,
      invoice_status: "draft",
      invoice_type: "draft",
      GrandTotal: 0,
      clientCurrentPage: 1, // Current page number
      clientPageSize: 4, // Number of items per page
      itemCurrentPage: 1, // Current pagination page
      itemPageSize: 4, // Items per page
      mark_fully_paid: false,
    };
  },
  created() {
    this.runMountedFunctions();
  },
  watch: {
    searchQuery() {
      this.clientCurrentPage = 1; // Reset to first page on search
      this.filterClients();
    },
    client_server_data() {
      this.filterClients();
    },
    dialogVisibleItemsPopup(newValue) {
      if (!newValue) {
        // Close the Bootstrap modal if Element UI dialog is closed
        const bootstrapModal = document.getElementById("addItemToList");
        if (bootstrapModal) {
          const bsModalInstance = Modal.getInstance(bootstrapModal);
          if (bsModalInstance) {
            bsModalInstance.hide();
          }
        }
      }
    },
    totalPriceCalculation() {
      this.totalPrice = this.totalPriceCalculation;
    },
    discount(newVal) {
      console.log("Discount changed to:", newVal);
    },
    subtotalCalculation() {
      this.subTotalPrice = this.subtotalCalculation;
      let tax1Amount = 0;
      let tax2Amount = 0;

      if (this.tax_1_rate > 0) {
        tax1Amount = (this.subTotalPrice * this.tax_1_rate) / 100;
      }

      if (this.tax_2_rate > 0) {
        tax2Amount = (this.subTotalPrice * this.tax_2_rate) / 100;
      }

      let paymentTotal = 0;
      if (this.payment.length > 0) {
        paymentTotal = this.payment
          .reduce((total, pmt) => {
            return total + parseFloat(pmt.submitted_amount);
          }, 0)
          .toFixed(2);
      }

      this.GrandTotal = (this.subtotalCalculation + tax2Amount + tax1Amount).toFixed(2);
      this.outstanding_due = (this.GrandTotal - paymentTotal).toFixed(2);
    },
  },
  computed: {
    pageTitle() {
      return (document.title = `${this.title.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      )} | Smoothbalance`);
    },
    itemPaginatedList() {
      const start = (this.itemCurrentPage - 1) * this.itemPageSize;
      const end = start + this.itemPageSize;
      return this.selectedItemsList.slice(start, end);
    },
    selectedItemsList() {
      let selectedItems = this.$store.getters["item/getSelectedItemsList"];
      return selectedItems;
    },
    clientPaginatedData() {
      const start = (this.clientCurrentPage - 1) * this.clientPageSize;
      const end = start + this.clientPageSize;
      return this.client_server_data.slice(start, end);
    },
    totalSubmittedAmount() {
      if (this.payment.length > 0) {
        return this.payment
          .reduce((total, pmt) => {
            return total + parseFloat(pmt.submitted_amount);
          }, 0)
          .toFixed(2);
      }
    },
    totalPriceCalculation() {
      let selectedItems = this.$store.getters["item/getSelectedItemsList"];
      return selectedItems.reduce((total, item) => {
        const itemPrice = parseInt(item.quantity) * parseFloat(item.unit_price);
        return total + itemPrice;
      }, 0);
    },
    subtotalCalculation() {
      let total = this.totalPriceCalculation;
      let discountedTotal = 0;

      if (this.isPercentChecked) {
        discountedTotal = total - (total * this.discount) / 100;
        this.discountTypeValue = (total * this.discount) / 100;
        this.discountType = "percent";
      } else {
        discountedTotal = total - this.discount;
        this.discountTypeValue = this.discount;
        this.discountType = "amount";
      }

      // Return the computed subtotal including taxes
      return discountedTotal;
    },
    currencyOptions() {
      if (this.currency) {
        return this.currency.map((v) => {
          return {
            id: v.id,
            name: v.name,
            symbol: v.symbol,
          };
        });
      }
      return [];
    },
    vatTax() {
      return ((this.subTotalPrice * this.tax_1_rate) / 100).toFixed(2);
    },
    vatTax2() {
      return ((this.subTotalPrice * this.tax_2_rate) / 100).toFixed(2);
    },
    displayedClients() {
      const startIndex = (this.clientCurrentPage - 1) * this.clientPageSize;
      const endIndex = startIndex + this.clientPageSize;
      return this.filteredClients.slice(startIndex, endIndex);
    },
  },
  methods: {
    runMountedFunctions() {
      this.fetchCurrency();
      this.getInvoiceNumber();
      this.getTaxDetails();
      this.fetchPaymentMethod();
    },
    fullyPaidToggle() {
      console.log(this.mark_fully_paid);

      if (this.mark_fully_paid) {
        this.pay_now_amount = this.outstanding_due;
      } else {
        this.pay_now_amount = 0;
      }
    },
    filterClients() {
      if (!this.searchQuery) {
        this.filteredClients = [...this.client_server_data]; // Important: Create a copy
        return;
      }

      const searchTerm = this.searchQuery.toLowerCase();
      this.filteredClients = this.client_server_data.filter((client) => {
        return (
          client.full_name.toLowerCase().includes(searchTerm) ||
          client.business_name.toLowerCase().includes(searchTerm) ||
          client.email.toLowerCase().includes(searchTerm)
        );
      });
    },
    itemHandlePageChange(newPage) {
      this.itemCurrentPage = newPage;
    },
    clientHandlePageChange(newPage) {
      this.clientCurrentPage = newPage;
    },
    selectClient(client) {
      this.client = client.id;
      this.clientName = client.business_name;
      const bootstrapModal = document.getElementById("getClient");
      if (bootstrapModal) {
        const bsModalInstance = Modal.getInstance(bootstrapModal);
        if (bsModalInstance) {
          bsModalInstance.hide();
        }
      }
    },
    getInvoiceItems() {
      const selectedItems = this.$store.getters["item/getSelectedItemsList"];

      // Map through the selected items to transform them
      const transformedItems = selectedItems.map((item) => {
        const { name, unit_price, description, quantity, type } = item;

        // Calculate the total by multiplying quantity and unit_price
        const total = parseFloat(unit_price) * parseFloat(quantity);

        // Return a new object with required keys and the calculated total
        return {
          name,
          unit_price,
          description,
          quantity,
          type,
          total, // Add the calculated total to the object
        };
      });
      return transformedItems;
    },
    validateInvoice() {
      const errors = [];

      // Common required fields
      if (!this.invoice_status) errors.push("Invoice status is required.");
      if (!this.invoiceNumber) errors.push("Invoice number is required.");
      if (!this.client) errors.push("Client ID is required.");
      if (!this.terms) errors.push("Validity term is required.");
      if (!this.currency_id) errors.push("Currency ID is required.");

      // Additional required fields for non-draft statuses
      if (this.invoice_status !== "draft") {
        if (!this.invoice_date) errors.push("Invoice date is required.");
        if (!this.totalPrice) errors.push("Total Gross Amount is required.");
        // if (!this.subTotalPrice) errors.push("Total Net Amount is required.");
        // if (!this.total_amount_paid) errors.push("Total Paid Amount is required.");
        if (this.tax_1_rate === undefined || this.tax_1_rate === null) {
          errors.push("VAT percent is required.");
        }
      }

      // Show errors if any
      if (errors.length > 0) {
        errors.forEach((error) => this.$toast.error(error));
        return false;
      }

      return true;
    },
    saveInvoice() {
      if (!this.validateInvoice()) {
        return; // Stop if validation fails
      }
      const data = {
        total_amount_gross: this.totalPrice,
        invoice_status: this.invoice_status,
        invoice_type: this.invoice_type,
        invoice_date: this.invoice_date,
        invoice_number: this.invoiceNumber,
        client_id: this.client,
        validity_term: this.terms,
        currency_id: this.currency_id,
        payment: this.payment,
        remaining_due: this.outstanding_due,
        notes: this.note,
        invoice_items: this.getInvoiceItems(),
        vat_percent: this.tax_1_rate,
        vat_name: this.tax_1_name,
        tax1_percent: this.tax_2_rate,
        tax1_name: this.tax_2_name,
        vat_amount: this.vatTax,
        tax1_amount: this.vatTax2,
        is_image: this.image_list.length > 0 ? "Y" : "N",
        sub_total: this.subTotalPrice,
        discount_value: this.discountTypeValue,
        discount_type: this.discountType,
      };

      this.$store
        .dispatch("invoice/saveInvoice", data)
        .then((res) => {
          if (res.data.status == 1) {
            localStorage.setItem("invoice-details", JSON.stringify(res.data.response));
            if (this.image_list.length > 0) {
              this.saveInvoiceAttachment(res.data.response.id);
            } else {
              this.$router.push({ name: "show-invoice" });
            }
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
    saveInvoiceAttachment(id) {
      // Create a new FormData object
      const form_data = new FormData();

      // Append the invoice_id to FormData
      form_data.append("invoice_id", id);

      // Append each file to FormData with the key "invoice_attachments[]"
      this.image_list.forEach((image) => {
        form_data.append("invoice_attachments[]", image.file);
      });

      this.$store
        .dispatch("invoice/saveInvoiceAttachment", form_data)
        .then((res) => {
          if (res.data.status == 1) {
            this.$router.push({ name: "show-invoice" });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
    changeCurrency() {
      this.currency_symbol = this.currency.find(
        (currency) => currency.id === this.form.currency_id
      ).symbol;
    },
    async fetchCurrency() {
      this.currency = await this.axios
        .get(CURRENCY_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchPaymentMethod() {
      this.payment_method_list = await this.$store
        .dispatch("expenses/getPaymentOptions")
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            return res.response;
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
    getTaxDetails() {
      this.$store
        .dispatch("setting/getTaxDetails")
        .then((res) => {
          if (res.data.status == 1 && res.data.response != null) {
            this.tax_1_rate = res.data.response.tax1_rate;
            this.tax_1_name = res.data.response.tax1_name;
            this.tax_1_status_enable = res.data.response.is_tax1_enable;
            this.tax_2_rate = res.data.response.tax2_rate;
            this.tax_2_name = res.data.response.tax2_name;
            this.tax_2_status_enable = res.data.response.is_tax2_enable;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        });
    },
    handleCheckboxChange(type) {
      if (type === "percent" && this.isPercentChecked) {
        this.isCashChecked = false;
      } else if (type === "cash" && this.isCashChecked) {
        this.isPercentChecked = false;
      }
    },
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    filterExpenses() {
      console.log(this.search);
    },
    showItemListPopup() {
      this.dialogVisibleItemsPopup = true;
    },
    removeItem(item_no) {
      this.$store.commit("item/removeFromRecentItemsInvoice", item_no);
    },
    getClientData() {
      this.showLoading();
      this.$store
        .dispatch("client/getListByUser", this.form)
        .then((res) => {
          if (res.status == 1) {
            this.client_server_data = res.response;
            // this.$toast.success("Fetch data Successful.");
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.client_server_data = [];
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getInvoiceNumber() {
      this.showLoading();
      this.$store
        .dispatch("invoice/getInvoiceNumber")
        .then((res) => {
          if (res.data.status == 1) {
            this.invoiceNumber = res.data.response.response;
            console.loog(res);
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$toast.error("Resource not found");
          }
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    onFileChange(e) {
      const files = Array.from(e.target.files);
      const maxSize = 512 * 1024; // 512 KB in bytes

      // Validate all files first
      const invalidFiles = files.filter((file) => file.size > maxSize);
      if (invalidFiles.length > 0) {
        invalidFiles.forEach((file) => {
          this.$toast.error(`File "${file.name}" exceeds 512 KB.`);
        });
        e.target.value = null; // Clear input
        return;
      }

      // Process valid files
      this.image_list = files.map((file) => ({
        file, // Store the file object
        url: URL.createObjectURL(file), // Generate a preview URL
      }));

      this.image_count = files.length;
      this.is_image = files.length ? "Y" : "N";
    },
    addPayment() {
      if (this.payment_date == "" || this.payment_method == "") {
        return false;
      }
      // Create a payment object based on the input values
      const newPayment = {
        submitted_amount: this.pay_now_amount,
        submission_date: this.payment_date,
        amount: this.outstanding_due,
        status:
          this.pay_now_amount >= this.outstanding_due ? "fully_paid" : "partially_paid",
        method: this.payment_method,
        description: this.pay_now_description,
      };

      this.invoice_status = "partpaid";
      this.invoice_type = "partpaid";

      if (this.pay_now_amount >= this.outstanding_due) {
        this.invoice_status = "paid";
        this.invoice_type = "paid";
      }

      // Push the newPayment object into the payment array
      this.payment.push(newPayment);

      // Clear the input fields or perform any other necessary actions after adding the payment
      this.pay_now_amount = "";
      this.payment_date = "";
      this.outstanding_due = "";
      this.payment_method = "";
      this.pay_now_description = "";

      let selectedItems = this.$store.getters["item/getSelectedItemsList"];
      let total_price = selectedItems.reduce((total, item) => {
        const itemPrice = parseInt(item.quantity) * parseFloat(item.unit_price);
        return total + itemPrice;
      }, 0);

      let discountedTotal = 0;
      if (this.isPercentChecked) {
        discountedTotal = total_price - (total_price * this.discount) / 100;
        this.discountTypeValue = (total_price * this.discount) / 100;
      } else {
        discountedTotal = total_price - this.discount;
        this.discountTypeValue = this.discount;
      }

      this.discountTypeValue = this.discount;

      const paymentAmountSum = this.payment.reduce((total, item) => {
        return total + parseFloat(item.submitted_amount);
      }, 0);

      let tax1Amount = 0;
      let tax2Amount = 0;

      if (this.tax_1_rate > 0) {
        tax1Amount = (this.subTotalPrice * this.tax_1_rate) / 100;
      }

      if (this.tax_2_rate > 0) {
        tax2Amount = (this.subTotalPrice * this.tax_2_rate) / 100;
      }

      this.outstanding_due = discountedTotal + tax2Amount + tax1Amount;

      this.outstanding_due = this.outstanding_due - paymentAmountSum;

      this.outstanding_due = this.outstanding_due.toFixed(2);
    },
  },
};
</script>

<style scoped>
.el-carousel {
  border: 2px solid #ccc;
  padding: 10px;
}

.preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 2px solid #fff;
}
</style>
