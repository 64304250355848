<template>
  <!--dashboard main body start-->
  <div>
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header px-0">
        <div class="row">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3"
          >
            <div class="global-searchbar">
              <form class="base-form" @submit.prevent="searchData">
                <div class="input-group">
                  <div class="input-group-text">
                    <img src="@/assets/images/system/file-icon.svg" />
                  </div>
                  <input
                    v-model="form.search_field"
                    type="text"
                    name="globalSearch"
                    class="form-control"
                    placeholder="Search (Business name, client name, email, quotation number etc)"
                  />
                  <button type="submit" name="btnSearch" class="btn btn-primary">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </form>
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
          </div>
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-end"
          >
            <ul class="search-option-menu me-3">
              <li class="nav-item">
                <a href="#" class="nav-link list-view">
                  <img src="@/assets/images/system/list-icon.png" />
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link grid-view">
                  <img src="@/assets/images/system/grid-icon.png" />
                </a>
              </li>
              <li class="nav-item sorting">
                <input
                  type="checkbox"
                  class="sorting-check"
                  id="btn-sorting"
                  autocomplete="off"
                  v-model="quotation_sorting_desc"
                  @change="changeOrder"
                />
                <label class="btn btn-sorting" for="btn-sorting"
                  ><span class="sr-only">Sort</span>
                </label>
              </li>
              <li class="nav-item">
                <a href="/add-quotation" class="nav-link circleNav"
                  ><i class="fa fa-plus"></i>
                </a>
              </li>
              <li class="nav-item dropdown">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="fas fa-ellipsis-v"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="generatePdf"
                        >PDF &nbsp;<i class="fa fa-envelope append"></i
                      ></el-dropdown-item>
                      <el-dropdown-item @click="generateExcel"
                        >CSV &nbsp;<i class="fa fa-print append"></i
                      ></el-dropdown-item>
                      <el-dropdown-item @click="generateZip"
                        >Download Zip &nbsp;<i class="fa fa-download append"></i
                      ></el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10 mx-auto mt-3">
            <div class="global-filter-form">
              <form class="base-form form-xl">
                <div class="row g-2 d-flex justify-content-center">
                  <div class="col-sm-4">
                    <div class="form-floating">
                      <el-date-picker
                        v-model="form.start_date"
                        class="me-2"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                      />
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-floating">
                      <el-date-picker
                        v-model="form.end_date"
                        class="me-2"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <el-select v-model="form.category">
                      <el-option label="All" value="all"></el-option>
                      <el-option label="Approved" value="approved"></el-option>
                      <el-option label="Pending" value="pending"></el-option>
                      <el-option label="Rejected" value="rejected"></el-option>
                      <el-option label="Sent" value="sent"></el-option>
                      <el-option label="Draft" value="draft"></el-option>
                      <el-option label="Void" value="void"></el-option>
                    </el-select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="row justify-content-center">
          <!--repeatable block-->
          <div
            class="col-sm-9 mt-2"
            v-for="(quotation, index) in quotation_server_data"
            :key="index"
          >
            <!--repeatable block-->
            <div class="card card-horizontal-list quotationBrown">
              <div class="card-body">
                <div class="hover-slide">
                  <div class="form-check outliner">
                    <input class="form-check-input" type="checkbox" value="" />
                  </div>
                </div>
                <div class="card-content">
                  <span class="card-count">{{index+1}}.</span>
                  <ul class="label-list">
                    <li class="list-title">{{ quotation.client.business_name }}</li>
                    <li class="sub-title">QN: {{ quotation.quotation_number }}</li>
                    <li class="text-danger">
                      D: {{ quotation.quotation_date }} - {{ quotation.expiry_date }}
                    </li>
                    <li>E: {{ quotation.client.email }}</li>
                  </ul>
                  <ul class="card-action-nav flex-start-horizontal mt-3">
                    <li class="nav-item">
                      <a
                        href="#"
                        class="btn btn-secondary btn-sm"
                        ><i class="fas fa-percent"></i> Discount
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="#"
                        class="btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#expenseFile"
                        @click="showDetails(quotation.quotation_number)"
                        ><i class="fa fa-paperclip"></i> File
                      </a>
                    </li>
                    <li class="nav-item ms-2">
                      <a href="#" class="btn btn-info btn-sm"
                        ><i class="fa fa-edit"></i> Note
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-footer has-action flex-shrink-1">
                <div class="ellipsis-dropdown dropdown">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      <i class="fas fa-ellipsis-v text-white"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="{ action: 'edit', id: quotation.quotation_number }"
                          ><i class="fa fa-edit"></i
                        ></el-dropdown-item>
                        <el-dropdown-item
                          @click="
                            deleteData(quotation.quotation_id, quotation.client.client_id)
                          "
                          ><i class="fa fa-times text-danger"></i
                        ></el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <ul class="log-status center">
                  <li
                    :class="{
                      'list-item': true,
                      sent: quotation.quotation_type === 'sent',
                      approved: quotation.quotation_type === 'approved',
                      rejected: quotation.quotation_type === 'rejected',
                      expired: quotation.quotation_type === 'expired',
                      draft: quotation.quotation_type === 'draft',
                      void: quotation.quotation_type === 'void',
                      default: ![
                        'sent',
                        'approved',
                        'rejected',
                        'expired',
                        'draft',
                        'void',
                      ].includes(quotation.quotation_type),
                    }"
                  >
                    <a href="#" v-if="quotation.quotation_type === 'sent'"
                      ><i class="fas fa-paper-plane"></i> Sent</a
                    >
                    <a href="#" v-if="quotation.quotation_type === 'approved'"
                      ><i class="fas fa-check-square"></i> Approved</a
                    >
                    <a href="#" v-if="quotation.quotation_type === 'rejected'"
                      ><i class="fas fa-times-circle"></i> Rejected</a
                    >
                    <a href="#" v-if="quotation.quotation_type === 'expired'"
                      ><i class="fas fa-clock"></i> Expired</a
                    >
                    <a href="#" v-if="quotation.quotation_type === 'draft'"
                      ><i class="far fa-file-alt"></i> Draft</a
                    >
                    <a href="#" v-if="quotation.quotation_type === 'void'"
                      ><i class="fas fa-ban"></i> Void</a
                    >
                    <a
                      href="#"
                      v-if="
                        ![
                          'sent',
                          'approved',
                          'rejected',
                          'expired',
                          'draft',
                          'void',
                        ].includes(quotation.quotation_type)
                      "
                      >Unknown</a
                    >
                  </li>
                </ul>
                <div class="footer-list-wraper justify-content-end">
                  <ul class="footer-list _big">
                    <li>Amount</li>
                    <li class="count">&pound;{{ quotation.total_amount_net }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <!--ended repeatable block-->
          </div>
        </div>

        <div class="row mx-0 mt-5">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3"
          >
            <!-- <div class="px-5 d-flex justify-content-end">
              <div class="card-totalCount card-totalCount--sent">
                <div class="card-header">Sent</div>
                <div class="card-body">&pound;100.00</div>
              </div>

              <div class="card-totalCount card-totalCount--approved ms-4">
                <div class="card-header">Approved</div>
                <div class="card-body">&pound;100.00</div>
              </div>
            </div> 

            <div class="px-5 d-flex justify-content-end mt-5">
              <button type="button" name="btn" class="btn btn-offWhite">Close</button>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade mdl-messaging _info"
      id="expenseFile"
      aria-hidden="true"
      aria-labelledby="expenseFileLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Expense preview</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="message-card no-shadow m-1">
              <div class="abs-nav abs--tr me-n5">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :href="'/edit-quotation/' + popup_data.quotation_number"
                      ><i class="fa fa-edit"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-print"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-envelope"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fa fa-download"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fas fa-ban"></i> </a>
                  </li>
                </ul>
              </div>

              <div class="w-90">
                <ul class="list-group list-group--borderless list-group--compact">
                  <li class="list-group-item">
                    Quotation number: {{ popup_data.quotation_number }}
                  </li>
                  <li class="list-group-item">
                    Quotation date: {{ popup_data.quotation_date }}
                  </li>
                  <li class="list-group-item">
                    Vendor name: {{ popup_data.business_name }}
                  </li>
                  <li class="list-group-item">Vendor email: {{ popup_data.email }}</li>
                  <li class="list-group-item">Name: {{ popup_data.name }}</li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span
                      >Amount Gross: {{ popup_data.currency_symbol }}
                      {{ popup_data.total_amount_gross }}</span
                    >
                  </li>
                </ul>
              </div>

              <div class="card card--curve-05rem mt-3">
                <div class="card-body">
                  <p class="m-0">Note:</p>
                  <p class="m-0">
                    {{ popup_data.notes }}
                  </p>
                </div>
              </div>

              <div
                class="card card--curve-05rem mt-3 w-100 align-items-center justify-content-center"
              >
                <div class="w-auto p-2">
                  <div
                    v-for="(images_quotation, key) in popup_data.quotation_attachments"
                    v-bind:key="'inv_img' + key"
                  >
                    <img :src="images_quotation.url" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--dashboard main body end-->
</template>
<script>
import { CURRENCY_LIST } from "../../config/url";

export default {
  name: "QuotationComponent",

  data() {
    return {
      loadingInstance: null,
      show_total_expense: 0,
      errorMessage: "",
      quotation_server_data: [],
      expense_category: null,
      currency: null,
      payment_method: null,
      form: {
        search_field: "",
        start_date: "",
        category: "all",
        end_date: new Date().toISOString().split("T")[0],
      },
      expenses_preview: {
        id: "",
        expense_number: "",
        category: "",
        vendor: "",
        date: "",
        payment_method: "",
        note: "",
        currency_id: "",
        amount: "",
        image_url: [],
      },
      search: {
        date: "",
        category: "",
        currency: "",
        payment_method: "",
      },
      file_data: "",
      file_popup: false,
      note_popup: false,
      quotation_sorting_desc: false,
      popup_data: {
        quotation_number: "",
        total_amount_gross: "",
        total_amount_paid: "",
        quotation_attachments: "",
        business_name: "",
        email: "",
        name: "",
        notes: "",
        currency_symbol: "",
        quotation_date: "",
      },
    };
  },
  created() {},
  computed: {
    currencyOptions() {
      if (this.currency) {
        return this.currency.map((v) => {
          return {
            id: v.id,
            name: v.name,
            symbol: v.symbol,
          };
        });
      }
      return [];
    },
  },
  mounted() {
    // Set start date to six months before
    const currentDate = new Date();
    const sixMonthsBefore = new Date(currentDate);
    sixMonthsBefore.setMonth(currentDate.getMonth() - 6);
    this.form.start_date = sixMonthsBefore.toISOString().split("T")[0];
    this.fetchCurrency();
    this.getData();
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    handleCommand(command) {
      if (command.action === "edit") {
        // Navigate to the edit page
        this.$router.push(`/edit-quotation/${command.id}`);
      }
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("quotation/getList", this.form)
        .then((res) => {
          if (res.data.status == 1) {
            this.quotation_server_data = res.data.response;
            // this.$toast.success("Fetch data Successful.");
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    validateForm() {
      if (!this.form.start_date) {
        this.errorMessage = "Start date is required";
        return false;
      }
      if (!this.form.end_date) {
        this.errorMessage = "End date is required";
        return false;
      }
      // if (!this.form.search_field) {
      //   this.errorMessage = "Search field is required";
      //   return false;
      // }
      // Additional validations can be added here, such as date format checks
      return true;
    },
    async searchData() {
      this.showLoading();
      const data = {
        from_date: this.form.start_date,
        to_date: this.form.end_date,
        ...(this.form.search_field ? { search_field: this.form.search_field } : {}),
        category: this.form.category,
      };
      if (this.validateForm()) {
        await this.$store
          .dispatch("quotation/searchList", data)
          .then((res) => {
            if (res.data.status == 1) {
              this.quotation_server_data = res.data.response;
            } else {
              this.$toast.error("Something went wrong. Please try again.");
              throw new Error(res);
            }
          })
          .catch((err) => {
            console.log(err);
            // this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
      } else {
        this.hideLoading();
      }
    },
    async fetchCurrency() {
      this.currency = await this.axios
        .get(CURRENCY_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterExpenses() {
      console.log(this.search);
    },
    deleteData(quotationId, client_id) {
      this.showLoading();
      this.$store
        .dispatch("quotation/deleteQuotation", {
          quotation_id: quotationId,
          client_id: client_id,
        })
        .then((res) => {
          //   console.log(res);
          if (res.status == 1) {
            this.$toast.success("Delete Successful.");
            this.$nextTick(() => {
              this.getData();
            });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    changeOrder() {
      if (this.quotation_sorting_desc) {
        // Sort data in descending order
        this.quotation_server_data.sort(
          (a, b) => new Date(b.quotation_date) - new Date(a.quotation_date)
        );
      } else {
        // Sort data in ascending order
        this.quotation_server_data.sort(
          (a, b) => new Date(a.quotation_date) - new Date(b.quotation_date)
        );
      }
    },
    showDetails(id) {
      this.$store
        .dispatch("quotation/getQuotationByNumber", { quotation_number: id })
        .then((res) => {
          if (res.data.status == 1) {
            this.popup_data.quotation_number = res.data.response.quotation_number;
            this.popup_data.quotation_date = res.data.response.quotation_date;
            this.popup_data.total_amount_gross = res.data.response.total_amount_gross;
            this.popup_data.total_amount_paid = res.data.response.total_amount_paid;
            this.popup_data.quotation_attachments = res.data.response.quotation_attachments;
            this.popup_data.business_name = res.data.response.client.business_name;
            this.popup_data.email = res.data.response.client.email;
            this.popup_data.name = res.data.response.client.name;
            this.popup_data.notes = res.data.response.client.notes;
            this.popup_data.currency_symbol = res.data.response.currency.symbol;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style scoped>
.expenses-list-body .expenses-list-single {
  min-height: 150px;
}
</style>
